import React from 'react';
import {
  Modal,
  ImageCircle,
  Text,
  TextBlock,
  FlexContainer,
  MessengerBlock,
} from '@dotone/react-ui-core';
import { useTranslate } from '@hooks';
import { Card, CardImg, CardBody } from 'reactstrap';
import bg from '@assets/profile-bg.jpg';

const AffiliateDetailModal = ({ isOpen, onCancel, affiliate }) => {
  const { t } = useTranslate('affiliate', 'model');

  const {
    name,
    nickname,
    email,
    avatarCdnUrl,
    firstName,
    lastName,
    messengerService,
    messengerId,
    messengerService2,
    messengerId2,
  } = affiliate || {};

  const messengers = [
    { id: messengerId, service: messengerService },
    { id: messengerId2, service: messengerService2 },
  ].filter((m) => m.id && m.service);

  return (
    <Modal dense header={false} footer footerVariant="close" isOpen={isOpen} onToggle={onCancel}>
      <Card className="mb-0">
        <CardImg src={bg} style={{ height: 180 }} />
        <CardBody>
          <center className="border-bottom pb-3 mb-3 mx-ncard">
            <ImageCircle
              avatar
              src={avatarCdnUrl}
              width={128}
              style={{ marginTop: -80, marginBottom: 20 }}
            />
            <h3 className="text-dark mb-0">{name}</h3>
          </center>
          <div className="mb-3 ">
            <TextBlock label={t('email')}>
              {email ? (
                <a href={`mailto:${email}`} className="text-dark-light">
                  {email}
                </a>
              ) : (
                '*****'
              )}
            </TextBlock>
            <TextBlock label={t('nickname')} className="my-3">
              {nickname || '*****'}
            </TextBlock>
            <TextBlock label={t('firstName')} className="my-3">
              {firstName}
            </TextBlock>
            <TextBlock label={t('lastName')} className="my-3">
              {lastName || '*****'}
            </TextBlock>
          </div>
          {messengers.length > 0 && (
            <>
              <Text className="my-1">{t('social')}</Text>
              <FlexContainer wrap align="center" className="mx-n2 mb-3">
                {messengers.map(({ service, id }, index) => (
                  <MessengerBlock service={service} messengerId={id} className="mb-2" key={index} />
                ))}
              </FlexContainer>
            </>
          )}
        </CardBody>
      </Card>
    </Modal>
  );
};

export default AffiliateDetailModal;
