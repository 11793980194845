import React from 'react';
import { useTranslate, useCurrentUser } from '@hooks';
import { take } from 'lodash';
import { Table } from '@dotone/react-ui-core';
import { formatAffiliateName } from '../formatters';
import { formatAffiliateStats, formatAffiliateType, formatAvatar } from './formatters';

const getColumns = ({ t, currentUser }) => [
  {
    dataField: 'id',
    text: t('name'),
    classes: 'text-nowrap',
    formatter: (cell, row) => formatAffiliateName(row, { currentUser }),
  },
  {
    dataField: 'avatarCdnUrl',
    text: t('avatar'),
    formatter: (cell, row) => formatAvatar({ currentUser, width: 50, cell, row }),
    headerAlign: 'center',
    classes: 'text-center py-0',
    hidden: !currentUser.pro,
  },
  {
    dataField: 'affiliateType',
    text: t('affiliateType'),
    hidden: currentUser.pro,
    formatter: (cell, row) => formatAffiliateType(cell, { siteInfos: take(row.siteInfos, 1) }),
  },
  {
    dataField: 'stats',
    text: t('stats'),
    headerClasses: 'text-right',
    classes: 'text-right',
    formatter: (cell, row) => formatAffiliateStats(row),
  },
];

const AffiliateTableMini = (props) => {
  const { t } = useTranslate('affiliate', 'model');
  const { currentUser } = useCurrentUser();

  const columns = getColumns({ t, currentUser });

  return (
    <Table
      responsive
      remote
      hover
      classes="stylish-table mb-0"
      keyField="id"
      columns={columns}
      {...props}
    />
  );
};

export default AffiliateTableMini;
