import React from 'react';
import { useTranslate } from '@hooks';
import { conversionTypes } from '@dotone/react-ui-core/constants';
import { compact } from 'lodash';
import {
  Box,
  ConversionTypeBadge,
  Percentage,
  Currency,
  TextBlock,
  DateRange,
  FlexContainer,
} from '@dotone/react-ui-core';

const OrderConversionSnapshotDetails = ({ conversionStepSnapshot: snapshot = {} }) => {
  const { t, tHint } = useTranslate('order', 'model');

  const { startsAt, endsAt, trueConvType, truePay, trueShare, currencyCode } = snapshot;

  let dates = compact([startsAt, endsAt]);
  dates = dates.length ? dates : null;

  return (
    <Box
      label={t('order:hints.usingSnapshot.title')}
      labelProps={{ hintProps: tHint('order:hints.usingSnapshot') }}
      className="px-3 mb-3 py-4"
    >
      <FlexContainer>
        <TextBlock
          label={t('stat:model.trueConvType')}
          content={<ConversionTypeBadge value={trueConvType} />}
          className="mr-3"
        />
        <TextBlock
          label={t('truePay')}
          content={
            <FlexContainer align="center">
              {trueConvType === conversionTypes.CPS ? (
                <Percentage value={trueShare} />
              ) : (
                <Currency value={truePay} currency={currencyCode} />
              )}
              {dates && <DateRange values={dates} joiner={t('common:thru')} />}
            </FlexContainer>
          }
        />
      </FlexContainer>
    </Box>
  );
};

export default OrderConversionSnapshotDetails;
