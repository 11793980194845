import React, { useState } from 'react';
import { camelCase } from 'lodash';
import { useCurrentUser, useTranslate } from '@hooks';
import { TablePaginated } from '@dotone/react-ui-core';
import { AffiliateLogFormModal } from '../AffiliateLogs';
import {
  formatAvatar,
  formatAffiliateType,
  formatWebsite,
  formatLastJoinedAt,
  formatStatus,
} from './formatters';
import { formatAffiliateName, formatNotes } from '../formatters';
import FilterForm from './AffiliateFilterForm';
import FilterList from './AffiliateFilterList';

const getColumns = ({ t, tShared, currentUser, onNoteOpen }) => [
  {
    dataField: 'source',
    text: t('source'),
    hintProps: { t, tKey: 'affiliate:hints.source' },
    formatter: (cell) => cell && tShared(`source.${camelCase(cell)}`),
  },
  {
    dataField: 'id',
    text: t('name'),
    formatter: (cell, row) => formatAffiliateName(row, { currentUser }),
  },
  {
    dataField: 'avatarCdnUrl',
    text: t('avatar'),
    formatter: (cell, row) => formatAvatar({ currentUser, cell, row }),
    headerAlign: 'center',
    classes: 'text-center',
  },
  {
    dataField: 'affiliateType',
    text: t('affiliateType'),
    formatter: formatAffiliateType,
  },
  {
    dataField: 'siteInfos',
    text: t('siteInfo'),
    headerStyle: { maxWidth: '200px' },
    style: { maxWidth: '200px' },
    formatter: (cell, row) => formatWebsite({ t, currentUser, cell, row }),
  },
  {
    dataField: 'status',
    text: t('status'),
    formatter: formatStatus,
  },
  {
    dataField: 'lastJoinedAt',
    text: t('activity'),
    headerAlign: 'center',
    formatter: formatLastJoinedAt(t),
  },
  // {
  //   dataField: 'label',
  //   text: t('label'),
  //   formatter: formatLabel,
  // },
  {
    dataField: 'logs',
    text: t('notes'),
    formatter: (cell, row) => formatNotes({ cell, row, onNoteOpen }),
    classes: 'hover-overlay',
  },
];

const AffiliateTable = (props) => {
  const { t, tShared } = useTranslate('affiliate', 'model');
  const { currentUser } = useCurrentUser();
  const [current, setCurrent] = useState({});

  const handleToggle = () => {
    setCurrent({});
  };

  const columns = getColumns({ t, tShared, currentUser, onNoteOpen: setCurrent });
  return (
    <>
      <TablePaginated
        remote
        condensed
        responsive
        hover
        classes="stylish-table"
        keyField="id"
        columns={columns}
        filterRenderer={(filterProps) => <FilterForm {...filterProps} />}
        filterListRenderer={(filters) => <FilterList filters={filters} />}
        {...props}
      />
      {current?.id && (
        <AffiliateLogFormModal
          defaultValues={{ ownerId: current?.id, ownerType: 'Affiliate' }}
          isOpen={!!current?.id}
          onToggle={handleToggle}
          items={current?.logs || []}
        />
      )}
    </>
  );
};

export default AffiliateTable;
