import API from './api';

export const {
  AffiliateFeedApi,
  AffiliateTagApi,
  AppConfigApi,
  BankApi,
  CategoryApi,
  CategoryGroupApi,
  ChatbotStepApi,
  CountryApi,
  CurrencyApi,
  FaqFeedApi,
  TimezoneApi,
  TrafficTypeApi,
} = API.getShared();

export { default as AffiliateApi } from './AffiliateApi';
export { default as AffiliateLogApi } from './AffiliateLogApi';
export { default as AffiliateStatApi } from './AffiliateStatApi';
export { default as AffiliateUserApi } from './AffiliateUserApi';
export { default as ApiKeyApi } from './ApiKeyApi';
export { default as BalanceApi } from './BalanceApi';
export { default as CampaignApi } from './CampaignApi';
export { default as ChatMessageApi } from './ChatMessageApi';
export { default as ChatRoomApi } from './ChatRoomApi';
export { default as ContactApi } from './ContactApi';
export { default as DashboardApi } from './DashboardApi';
export { default as DownloadApi } from './DownloadApi';
export { default as EasyStoreSetupApi } from './EasyStoreSetupApi';
export { default as ImageCreativeApi } from './ImageCreativeApi';
export { default as MissingOrderApi } from './MissingOrderApi';
export { default as OfferApi } from './OfferApi';
export { default as OrderApi } from './OrderApi';
export { default as PasswordApi } from './PasswordApi';
export { default as ProductApi } from './ProductApi';
export { default as ReportApi } from './ReportApi';
export { default as SiteInfoApi } from './SiteInfoApi';
export { default as TextCreativeApi } from './TextCreativeApi';
export { default as UploadApi } from './UploadApi';
export { default as UserApi } from './UserApi';
