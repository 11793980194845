import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useShallowEqualSelector, useTranslate } from '@hooks';
import { bulkUpdateImageCreative } from '@actions';
import { Col } from 'reactstrap';
import {
  Form,
  Label,
  ActivePeriodDateTimePicker,
  FormGroup,
  CreativeStatusSelect,
} from '@dotone/react-ui-core';

const ImageCreativeBulkEditForm = ({ ids, onCancel, ...otherProps }) => {
  const dispatch = useDispatch();
  const { t, tHint } = useTranslate('imageCreative', 'model');

  const handleSubmit = (params) => {
    dispatch(bulkUpdateImageCreative(ids, params));
  };

  const { isLoading, status } = useShallowEqualSelector(
    ({ imageCreativeBulkUpdate }) => imageCreativeBulkUpdate
  );

  useEffect(() => {
    if (!isLoading && status === 'success') {
      onCancel();
    }
  }, [isLoading, status, onCancel]);

  return (
    <Form
      forModal
      onSubmit={handleSubmit}
      onCancel={onCancel}
      submitting={isLoading}
      submitLabel={t('common:actions.submit')}
      {...otherProps}
    >
      <ActivePeriodDateTimePicker
        hintProps={tHint('imageCreative:hints.activePeriod')}
        names={{
          start: 'activeDateStart',
          end: 'activeDateEnd',
          infinity: 'isInfinityTime',
        }}
        rules={{ required: false }}
      />
      <FormGroup row>
        <Col md={4} className="text-left  text-md-right">
          <Label className="text-custom">{t('status')}</Label>
        </Col>
        <Col md={8}>
          <CreativeStatusSelect variant="edit" name="status" />
        </Col>
      </FormGroup>
    </Form>
  );
};

export default ImageCreativeBulkEditForm;
