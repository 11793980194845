import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { useTranslate } from '@hooks';
import { updateUser } from '@actions';
import { hasTourSteps } from '@data';
import { Navbar, Nav, NavItem } from 'reactstrap';
import { HelpCircle as HelpIcon } from 'react-feather';
import {
  SidebarToggler,
  NavbarCollapseToggler,
  SidebarCollapseToggler,
  NavbarCollapse,
  LocaleDropdown,
} from '@dotone/react-ui-core';
import { NavbarBrand, CurrencyDropdown, TimezoneDropdown, AccountDropdown } from '@components';

const NavbarProtected = ({ currentUser, onToggleTour, dataLoading }) => {
  const { t } = useTranslate('common');
  const router = useRouter();
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);

  const handleChangeLocale = (locale) => {
    dispatch(updateUser(currentUser.id, { locale }, { notify: false }));
  };

  const handleCurrencyChange = (currencyId) => {
    dispatch(updateUser(currentUser.id, { currencyId }, false));
  };

  const handleTimezoneChange = (timeZoneId) => {
    dispatch(updateUser(currentUser.id, { timeZoneId }, false));
  };

  const handleToggleSidebar = () => {
    setOpen(false);
  };

  const handleToggleCollapse = () => {
    setOpen(!isOpen);
  };

  const handleToggleTour = (e) => {
    e.preventDefault();
    onToggleTour();
  };

  return (
    <Navbar expand="md" className="navbar-light top-navbar">
      <div className="navbar-header" data-logobg="skin6">
        <SidebarToggler onToggle={handleToggleSidebar} />
        <NavbarBrand href="/advertisers" />
        <NavbarCollapseToggler onToggle={handleToggleCollapse} />
      </div>
      <NavbarCollapse isOpen={isOpen}>
        <Nav navbar className="mr-auto">
          <NavItem>
            <SidebarCollapseToggler className="nav-link d-none d-md-block" />
          </NavItem>
          {!dataLoading && hasTourSteps(router.pathname, t) && (
            <NavItem>
              <a
                href="#"
                onClick={handleToggleTour}
                className="nav-link d-none d-md-block text-primary"
              >
                <HelpIcon size={16} className="mr-1" />
                {t('startTour')}
              </a>
            </NavItem>
          )}
        </Nav>
        {currentUser.id && (
          <Nav navbar className="justify-content-center">
            <LocaleDropdown nav value={router.locale} onChange={handleChangeLocale} />
            <CurrencyDropdown nav value={currentUser.currency} onChange={handleCurrencyChange} />
            <TimezoneDropdown nav value={currentUser.timeZone} onChange={handleTimezoneChange} />
            <AccountDropdown nav currentUser={currentUser} />
          </Nav>
        )}
      </NavbarCollapse>
    </Navbar>
  );
};

export default NavbarProtected;
