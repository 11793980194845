import React from 'react';
import { toSelectOptions } from '@dotone/react-ui-core/utils';
import { RadioGroup } from '@dotone/react-ui-core';

const OfferVariantRadioGroup = ({ offerVariants, ...otherProps }) => {
  const options = toSelectOptions(offerVariants, { label: 'fullName' });

  return <RadioGroup options={options} {...otherProps} />;
};

export default OfferVariantRadioGroup;
