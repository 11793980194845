import React, { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';
import { useRouter } from 'next/router';
import { useTranslate } from '@hooks';
import { lowerCase } from 'lodash';
import { envVars } from '@constants';
import classnames from 'classnames';
import { Navbar, Container, Nav, NavItem, Collapse, NavbarToggler } from 'reactstrap';
import { LocaleDropdown, FlexContainer } from '@dotone/react-ui-core';
import { NavbarBrand, NavLink } from '@components';

const maxWidthNavbarTogglerShown = 991;

const ExternalNavLink = (props) => <a className="nav-link" rel="noreferrer" {...props} />;

const NavbarPublic = ({ title, subtitle, bgSrc }) => {
  const { t, locale } = useTranslate('common', 'navbar');
  const router = useRouter();
  const { width } = useWindowSize();
  const [isOpen, setOpen] = useState(false);
  const { pathname } = router;

  const handleChangeLocale = (newLocale) => {
    router.push(router.asPath, router.asPath, { locale: newLocale });
  };

  const landingPagePath = (path) =>
    `${envVars.landingPageUrl}/${lowerCase(
      ['en-US', 'zh-TW'].includes(locale) ? locale : 'en-us'
    ).replace(' ', '-')}/${path}`;

  useEffect(() => {
    if (width > maxWidthNavbarTogglerShown) {
      setOpen(false);
    }
  }, [width]);

  return (
    <header
      className={classnames('topbar shadow-none position-static', { 'bg-transparent': !isOpen })}
      style={{ height: 200 }}
    >
      <Navbar
        expand="lg"
        className={classnames(
          'navbar-dark top-navbar flex-column align-items-start navbar-public',
          {
            'bg-transparent': !isOpen,
          }
        )}
        style={{
          background: `url(${bgSrc || '/static/images/login-cover.jpg'})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: 200,
        }}
      >
        <Container style={{ maxWidth: 1200 }} className="text-uppercase font-weight-bold">
          <NavbarBrand
            href="/advertisers"
            logoProps={{ style: { maxWidth: 200 }, isPublic: true }}
          />
          <NavbarToggler onClick={() => setOpen(!isOpen)} />
          <Collapse isOpen={isOpen} navbar>
            <Nav navbar className="ml-auto">
              <NavItem>
                <ExternalNavLink href={landingPagePath('index.html')}>{t('home')}</ExternalNavLink>
              </NavItem>
              <NavItem>
                <ExternalNavLink href={landingPagePath('events.html')}>
                  {t('events')}
                </ExternalNavLink>
              </NavItem>
              <NavItem>
                <ExternalNavLink href={landingPagePath('affiliates.html')}>
                  {t('affiliates')}
                </ExternalNavLink>
              </NavItem>
              <NavItem>
                <ExternalNavLink href={landingPagePath('advertisers.html')}>
                  {t('advertisers')}
                </ExternalNavLink>
              </NavItem>
              <NavItem>
                <ExternalNavLink href={landingPagePath('agencies.html')}>
                  {t('agencies')}
                </ExternalNavLink>
              </NavItem>
              <NavItem>
                <ExternalNavLink href={landingPagePath('blog')}>{t('blog')}</ExternalNavLink>
              </NavItem>
              <NavItem className={classnames({ active: pathname === '/advertisers/login' })}>
                <NavLink href="/advertisers/login">{t('login')}</NavLink>
              </NavItem>
              <NavItem className={classnames({ active: pathname === '/advertisers/register' })}>
                <NavLink href="/advertisers/register">{t('register')}</NavLink>
              </NavItem>
              <LocaleDropdown nav value={router.locale} onChange={handleChangeLocale} />
            </Nav>
          </Collapse>
        </Container>
        {title && (
          <FlexContainer
            column
            align="center"
            className="w-100 text-center flex-grow-1 text-white my-3"
          >
            <h1>{title}</h1>
            {subtitle && <div>{subtitle}</div>}
          </FlexContainer>
        )}
      </Navbar>
    </header>
  );
};

export default NavbarPublic;
