import React, { useState, useCallback, useEffect } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { useTranslate, useShallowEqualSelector } from '@hooks';
import { ChatbotStepApi } from '@api';
import { reauthenticate, deauthenticate, getCurrentUser } from '@actions';
import classnames from 'classnames';
import { findMenu } from '@utils';
import { chain } from 'lodash';
import { FlexContainer, LoaderContainer, Chatbot, Footer } from '@dotone/react-ui-core';
import { BreadcrumbSet, Tour } from '@components';
import NavbarProtected from './NavbarProtected';
import Sidebar from './Sidebar';

const LayoutProtected = ({
  title,
  loading,
  breadcrumbs = [],
  breadcrumbsHidden = false,
  children,
  className,
  ...otherProps
}) => {
  const { t } = useTranslate('common', 'sidebar');
  const router = useRouter();
  const dispatch = useDispatch();
  const [tourOpen, setTourOpen] = useState(false);
  const { isAuthenticated, deauthenticating } = useShallowEqualSelector(({ auth }) => auth);
  const { data: currentUser, status } = useShallowEqualSelector(({ currentUser: user }) => user);
  let newBreadcrumbs = breadcrumbs;
  if (!breadcrumbs.length) {
    const currentMenu = findMenu(router.pathname);
    if (currentMenu) {
      newBreadcrumbs = [{ title: t(currentMenu.label), props: { active: true } }];
    } else {
      newBreadcrumbs = [{ title, props: { active: true } }];
    }
  }

  const dataLoading = useShallowEqualSelector((allState) =>
    chain(allState)
      .map((state) => state.isLoading)
      .some()
      .value()
  );

  const handleToggleTour = useCallback(() => {
    setTourOpen((prev) => !prev);
  }, [setTourOpen]);

  const handleChatbotSearch = (message) =>
    ChatbotStepApi.search({ query: message, locale: currentUser.locale });

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(
        reauthenticate({
          redirectTo: deauthenticating ? null : router.asPath,
        })
      );
    }
  }, [dispatch, isAuthenticated, router, deauthenticating]);

  useEffect(() => {
    if (isAuthenticated && !currentUser.id) {
      dispatch(getCurrentUser());
    }
  }, [dispatch, isAuthenticated, currentUser.id]);

  useEffect(() => {
    if (status === 'error') {
      dispatch(deauthenticate());
    }
  }, [dispatch, status]);

  useEffect(() => {
    if (currentUser.locale && currentUser.locale !== router.locale) {
      router.push(router.asPath, router.asPath, { locale: currentUser.locale });
    }
  }, [router, currentUser.locale]);

  return (
    <>
      <Head>
        <title>Affiliates.One{title && ` - ${title}`}</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      {isAuthenticated && (
        <>
          <header className="topbar" data-navbarbg="skin6">
            <NavbarProtected
              isAuthenticated
              dataLoading={dataLoading || loading}
              currentUser={currentUser}
              onToggleTour={handleToggleTour}
            />
          </header>
          {currentUser.id && <Sidebar currentUser={currentUser} currentPath={router.pathname} />}
          <FlexContainer column align="stretch" className="page-wrapper flex-grow-1">
            <LoaderContainer loading={loading}>
              <FlexContainer
                column
                align="stretch"
                className={classnames('page-content padded', className)}
                {...otherProps}
              >
                {!breadcrumbsHidden && !!newBreadcrumbs.length && (
                  <BreadcrumbSet items={newBreadcrumbs} listClassName="p-0" />
                )}
                {children}
              </FlexContainer>
            </LoaderContainer>
            <Footer className="footer" protect />
            {!dataLoading && <Tour isOpen={tourOpen} onToggle={handleToggleTour} />}
            <Chatbot user={currentUser} onSearch={handleChatbotSearch} />
          </FlexContainer>
        </>
      )}
      {!isAuthenticated && <LoaderContainer loading={true} />}
    </>
  );
};

export default LayoutProtected;
