import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useShallowEqualSelector, useTranslate } from '@hooks';
import { bulkUpdateStats } from '@actions';
import { Col } from 'reactstrap';
import { Form, Label, FormGroup, StatApprovalSelect } from '@dotone/react-ui-core';

const StatBulkEditForm = ({ ids, onCancel, ...otherProps }) => {
  const { t } = useTranslate('common');
  const dispatch = useDispatch();
  const { isLoading, status } = useShallowEqualSelector(({ statBulkUpdate }) => statBulkUpdate);

  const handleSubmit = (values) => {
    dispatch(bulkUpdateStats(ids, values));
  };

  useEffect(() => {
    if (!isLoading && status === 'success') {
      onCancel();
    }
  }, [isLoading, status, onCancel]);

  return (
    <Form
      forModal
      onSubmit={handleSubmit}
      onCancel={onCancel}
      submitting={isLoading}
      submitLabel={t('actions.submit')}
      {...otherProps}
    >
      <FormGroup row>
        <Col md={4} className="text-left text-md-right">
          <Label>{t('stat:filter.status')}</Label>
        </Col>
        <Col md={8}>
          <StatApprovalSelect final name="approval" />
        </Col>
      </FormGroup>
    </Form>
  );
};

export default StatBulkEditForm;
