import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from '@dotone/react-ui-core/hooks';
import { useTranslate, useCurrentUser, useShallowEqualSelector, useSearchSelector } from '@hooks';
import { createImageCreative, updateImageCreative } from '@actions';
import { Row, Col } from 'reactstrap';
import {
  FormBasic as Form,
  Label,
  Alert,
  FormGroup as BsFormGroup,
  Input,
  AlertPopover,
  FileUploader,
  LocaleCheckInput,
  CardBasic,
  DeepLinkInput,
  ActivePeriodDateTimePicker,
} from '@dotone/react-ui-core';
import { OfferSearchInput, OfferVariantRadioGroup } from '../forms';

const FormGroup = ({ label, required = false, hintProps, ...otherProps }) => (
  <BsFormGroup row>
    <Col md={4} className="text-left text-md-right">
      <Label className="text-sm text-nowrap">
        {required && '*'}
        {label}
        {hintProps && <AlertPopover {...hintProps} />}
      </Label>
    </Col>
    <Col md={8} {...otherProps} />
  </BsFormGroup>
);

const ImageCreativeForm = ({ imageCreative = {}, onCancel, ...otherProps }) => {
  const { locale, t } = useTranslate('imageCreative', 'model');
  const { tHint, tHtml } = useTranslate('imageCreative', 'hints');
  const dispatch = useDispatch();
  const { currentUser } = useCurrentUser();

  const newRecord = !imageCreative.id;

  const defaultValues = {
    ...imageCreative,
    ...(newRecord ? { images: [] } : {}),
  };

  const { handleSubmit, connectForm, watch, setValue } = useForm({ defaultValues });

  const [offerId, offerVariantId, images, cdnUrl] = watch([
    'offerId',
    'offerVariantId',
    'images',
    'cdnUrl',
  ]);

  const { submitting, isSubmitSuccess } = useShallowEqualSelector(
    ({ imageCreativeCreate: create, imageCreativeUpdate: update }) => ({
      isSubmitSuccess: create.status === 'success' || update.status === 'success',
      submitting: create.isLoading || update.isLoading,
    })
  );

  const { data: offers } = useSearchSelector(({ offerSearch }) => offerSearch, {
    ids: [offerId],
    locale,
    active: newRecord,
    offerVariants: true,
  });

  // Setup for toggling offer variant selection
  const selectedOffer = offerId && offers.find((offer) => String(offer.id) === String(offerId));
  const offerVariant = selectedOffer?.offerVariants?.find((variant) =>
    offerVariantId ? String(offerVariantId) === String(variant.id) : variant.isDefault
  );
  const destinationUrl = selectedOffer?.destinationUrl || offerVariant?.destinationUrl;
  let destinationDomain;
  try {
    destinationDomain = new URL(destinationUrl).origin;
  } catch {
    // skip domain when url invalid
  }

  const handleSubmitForm = ({ id, ...values }) => {
    if (id) {
      dispatch(updateImageCreative(id, values));
    } else {
      dispatch(createImageCreative(values));
    }
  };

  const handleImageUpload = (values) => {
    if (newRecord) {
      setValue('images', values);
    } else {
      setValue('cdnUrl', values.cdnUrl);
      setValue('width', values.width);
      setValue('height', values.height);
    }
  };

  useEffect(() => {
    if (!submitting && isSubmitSuccess && onCancel) {
      onCancel();
    }
  }, [isSubmitSuccess, submitting, onCancel]);

  return (
    <Form
      forModal
      onSubmit={handleSubmit(handleSubmitForm)}
      onCancel={onCancel}
      submitting={submitting}
      submitLabel={t('imageCreative:actions.submit')}
      {...otherProps}
    >
      {connectForm(
        <Row>
          <Col xs={12} lg={6}>
            <CardBasic className="border border-light" title={t('form.landingPageTitle')}>
              <FormGroup label={t('locales')} hintProps={tHint('locale')} required>
                <LocaleCheckInput name="locales" rules={{ required: true }} />
              </FormGroup>
              <FormGroup label={t('common:model.offer')} hintProps={tHint('offer')} required>
                <OfferSearchInput
                  name="offerId"
                  active={newRecord}
                  searchParams={{ offerVariants: true }}
                  required
                />
              </FormGroup>
              {!!selectedOffer?.offerVariants?.length && (
                <FormGroup
                  label={t('common:model.offerVariant')}
                  hintProps={tHint('offerVariant')}
                  required
                >
                  <OfferVariantRadioGroup
                    name="offerVariantId"
                    offerVariants={selectedOffer.offerVariants}
                    rules={{ required: true }}
                  />
                </FormGroup>
              )}
              {offerVariant?.deeplinkable && (
                <>
                  <Row>
                    <Col md={{ size: 8, offset: 4 }} lg={{ size: 8, offset: 4 }}>
                      <Alert variant="warning">
                        <div>{tHtml('clientUrl', { url: destinationUrl })}</div>
                      </Alert>
                    </Col>
                  </Row>
                  <FormGroup label={t('clientUrl')}>
                    <DeepLinkInput
                      name="clientUrl"
                      domain={destinationDomain}
                      placeholder={t('form.clientUrlPlaceholder')}
                    />
                  </FormGroup>
                </>
              )}
            </CardBasic>
          </Col>
          <Col xs={12} lg={6}>
            <CardBasic className="border border-light" title={t('form.campaignPeriodTitle')}>
              <ActivePeriodDateTimePicker
                hintProps={tHint('activePeriod')}
                names={{
                  start: 'activeDateStart',
                  end: 'activeDateEnd',
                  infinity: 'isInfinityTime',
                }}
              />
            </CardBasic>
            <CardBasic className="border border-light" title={t('form.imageUploadTitle')}>
              <FileUploader
                image
                path={`image_creative/network/${currentUser.id}`}
                onSuccess={handleImageUpload}
                previewLinks={newRecord ? images.map((image) => image.cdnUrl) : cdnUrl}
                maxFiles={newRecord ? 10 : 1}
              />
              <Input
                type="hidden"
                name={newRecord ? 'images' : 'cdnUrl'}
                rules={{ required: true }}
              />
            </CardBasic>
          </Col>
        </Row>
      )}
    </Form>
  );
};

export default ImageCreativeForm;
