import React from 'react';
import { useTranslate } from '@hooks';
import { statApprovals } from '@dotone/react-ui-core/constants';
import { statDataTypes } from '@constants';
import { camelCase } from 'lodash';
import { UncontrolledTooltip } from 'reactstrap';
import { TablePaginated, Currency, StatApprovalBadge } from '@dotone/react-ui-core';
import { formatConvType, formatDateTime, formatOfferStatus, formatDaysReturn } from '../formatters';
import StatColumnForm from './StatColumnForm';
import StatFilterList from './StatFilterList';
import StatFilterForm from './StatFilterForm';
import StatSearchForm from './StatSearchForm';
import { OrderFormModal } from '../Orders';

const formatApproval = ({ tShared, cell, row, index }) => {
  if (cell) {
    const id = `stat-approval-${index}`;

    const hintProps =
      cell === statApprovals.INVALID && row.order
        ? { tKey: id, title: tShared(`order:status.${camelCase(row.order.status)}`) }
        : null;

    return (
      <>
        <StatApprovalBadge block value={cell} id={id} hintProps={hintProps} />
        {cell === statApprovals.REJECTED && row.order && (
          <UncontrolledTooltip target={id}>
            {tShared(`order:status.${camelCase(row.order.status)}`)}
          </UncontrolledTooltip>
        )}
      </>
    );
  }
  return null;
};

const formatOrder = ({ row, cell }) =>
  row.order && (
    <OrderFormModal
      order={row.order}
      buttonProps={{ color: 'link', children: cell, className: 'p-0 text-sm' }}
    />
  );

const subidColumnProps = {
  classes: 'text-truncate',
  style: {
    maxWidth: 50,
  },
};

const getColumns = ({ t, tShared }) => [
  {
    dataField: 'copyStatId',
    headerAlign: 'center',
    classes: 'text-center',
    hidden: true,
  },
  {
    dataField: 'approval',
    text: t('approval'),
    formatter: (cell, row, index) => formatApproval({ tShared, cell, row, index }),
    sort: true,
  },
  {
    dataField: 'recordedAt',
    text: t('recordedAt'),
    formatter: formatDateTime(),
    sort: true,
  },
  {
    dataField: 'capturedAt',
    text: t('capturedAt'),
    formatter: formatDateTime(),
    sort: true,
  },
  {
    dataField: 'publishedAt',
    text: t('publishedAt'),
    formatter: formatDateTime(),
    sort: true,
  },
  {
    dataField: 'convertedAt',
    text: t('convertedAt'),
    formatter: formatDateTime(),
    sort: true,
  },
  {
    dataField: 'offerId',
    text: t('offerId'),
    sort: true,
  },
  {
    dataField: 'offer.name',
    text: t('offerName'),
    sort: true,
  },
  {
    dataField: 'trueConvType',
    text: t('trueConvType'),
    formatter: formatConvType,
  },
  {
    dataField: 'offer.status',
    text: t('offerStatus'),
    alias: 'offerStatus',
    formatter: formatOfferStatus,
  },
  {
    dataField: 'order.orderNumber',
    text: t('orderNumber'),
    alias: 'orderNumber',
    sort: true,
    formatter: (cell, row) => formatOrder({ cell, row }),
  },
  {
    dataField: 'orderTotal',
    text: t('orderTotal'),
    sort: true,
    formatter: (cell) => <Currency value={cell} round={false} />,
  },
  {
    dataField: 'advUniqId',
    text: t('advUniqId'),
    sort: true,
  },
  {
    dataField: 'stepLabel',
    text: t('stepLabel'),
  },
  {
    dataField: 'stepName',
    text: t('stepName'),
  },
  {
    dataField: 'order.daysReturn',
    text: t('orderDaysReturn'),
    alias: 'orderDaysReturn',
    formatter: (cell, row) => formatDaysReturn(cell, row, t),
  },
  {
    dataField: 'truePay',
    text: t('truePay'),
    sort: true,
    formatter: (cell) => <Currency value={cell} round={false} />,
  },
  {
    dataField: 'transactionId',
    text: t('transactionId'),
  },
  {
    dataField: 'affiliateId',
    text: t('affiliateId'),
  },
  {
    dataField: 'subid1',
    text: t('subid1'),
    ...subidColumnProps,
  },
  {
    dataField: 'subid2',
    text: t('subid2'),
    ...subidColumnProps,
  },
  {
    dataField: 'subid3',
    text: t('subid3'),
    ...subidColumnProps,
  },
  {
    dataField: 'subid4',
    text: t('subid4'),
    ...subidColumnProps,
  },
  {
    dataField: 'subid5',
    text: t('subid5'),
    ...subidColumnProps,
  },
  {
    dataField: 'ipAddress',
    text: t('ipAddress'),
  },
  {
    dataField: 'country.name',
    text: t('ipCountry'),
    alias: 'ipCountry',
  },
  {
    dataField: 'isp',
    text: t('isp'),
  },
  {
    dataField: 'deviceType',
    text: t('deviceType'),
  },
  {
    dataField: 'deviceBrand',
    text: t('deviceBrand'),
  },
  {
    dataField: 'deviceModel',
    text: t('deviceModel'),
  },
  {
    dataField: 'browser',
    text: t('browser'),
  },
  {
    dataField: 'browserVersion',
    text: t('browserVersion'),
  },
];

const StatTable = ({ dataType, filterable = true, ...otherProps }) => {
  const { t, tShared } = useTranslate('stat', 'model');

  const columns = getColumns({ t, tShared });

  const tableProps = {
    classes: 'text-sm stylish-table',
    columns,
    condensed: true,
    responsive: true,
    filterListRenderer: filterable ? (filters) => <StatFilterList filters={filters} /> : null,
    filterRenderer: filterable ? (filterProps) => <StatFilterForm {...filterProps} /> : null,
    columnRenderer: (columnProps) => <StatColumnForm dataType={dataType} {...columnProps} />,
    searchRenderer: (searchProps) => <StatSearchForm {...searchProps} />,
    hover: true,
    keyField: 'copyStatId',
    remote: true,
    selectable: dataType !== statDataTypes.CLICKS,
    searchable: true,
    checkVisibility: true,
    ...otherProps,
  };

  return <TablePaginated {...tableProps} />;
};

export default StatTable;
